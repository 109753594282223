<template>
  <b-container fluid id="webflowhfooter">
    <div>
      <footer class="gide-footer">
        <div class="row">
          <!-- Row one -->
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4">
            <ul>
              <li class="footer-header">
                <a href="https://www.gide.ai/career-assessment/gpaths">Career Assessment</a>
              </li>
              <li>
                <a href="https://www.gide.ai/premium-career-counselling">Premium  Counseling</a>
              </li>
              <li>
                <a href="https://www.gide.ai/student-club">Student Club</a>
              </li>
              <li>
                <a href="https://www.gide.ai/study-abroad">Study Abroad</a>
              </li>
            </ul>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4">
            <ul>
              <li class="footer-header">
                <a>TEST PREP</a>
              </li>
              <li>
                <a href="https://www.gide.ai/test-prep/sat-prep">SAT®</a>
              </li>
              <li>
                <a href="https://www.gide.ai/test-prep/advanced-placement-ap">AP®</a>
              </li>
              <li>
                <a href="https://www.gide.ai/test-prep/ielts">IELTS®</a>
              </li>
              <li>
                <a href="https://www.gide.ai/test-prep/act">ACT®</a>
              </li>
            </ul>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4" style="padding-left: 44px;">
            <div class="row">
              <div class="col-12" style="margin-bottom: 20px;">
                  <span class="footer-header">
                    SOCIAL MEDIA
                  </span>
              </div>
              <div class="social-icons col-12" style="margin-bottom: 20px;">
                  <div>
                    <a class="social_link" href="https://youtube.com/@GIDEAI" ><i class="fa-brands fa-youtube"></i></a>
                  </div>
                  <div class="facebook">
                    <a class="social_link" href="https://www.facebook.com/hs.m.1426?mibextid=ZbWKwL"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                  </div>
                  <div class="">
                    <a class="social_link" href="https://www.instagram.com/gide.ai?igsh=MTAxbGljcHZxcnEyZg%3D%3D"><i class="fa fa-instagram insta" aria-hidden="true"></i></a>
                  </div>
                  <div class="">
                    <a class="social_link" href="https://www.linkedin.com/company/gide-ai"><i class="fab fa-linkedin-in insta"></i></a>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12" style="margin-bottom: 20px;">
                  <span class="footer-header">
                    OUR COMMUNITIES
                  </span>
              </div>
              <div class="col-12 our_communities">
                <div class="footer-logos-block">
                  <a href="https://www.gide.ai/about-us/our-communities"><img class="footer-logos" src="https://cdn.prod.website-files.com/6509665fd667420652f013c1/65e986a6c2e0e2c23eb9c07b_HSM%20white%20LOGO%20REGISTERED%20(2)-p-500.png"/></a>
                </div>
                <div class="">
                  <a><img class="footer-logos" href="https://www.gide.ai/about-us/our-communities" src="https://cdn.prod.website-files.com/6509665fd667420652f013c1/65e98c1ad27bba43161f8d87_INACE%20WHITE-p-500.png"/></a>
                </div>
                <div class="">
                  <a><img class="footer-logos" href="https://www.gide.ai/about-us/our-communities" src="https://cdn.prod.website-files.com/6509665fd667420652f013c1/65e98c1a31950bf8173b9179_TEACHERS(3)-p-500.png"/></a>
                </div>
              </div>
            </div>
          </div>
          <!-- Row one -->
          <!-- Row two -->
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4">
            <ul>
              <li class="footer-header">
                <a>ABOUT US</a>
              </li>
              <li>
                <a href="https://www.gide.ai/about-us/who-we-are">Who We are</a>
              </li>
              <li>
                <a href="https://www.gide.ai/our-glories">Our glories</a>
              </li>
              <li>
                <a href="https://www.gide.ai/about-us/our-communities">Our Communities</a>
              </li>
              <li>
                <a :href="BLOGLIST">Our Blogs</a>
              </li>
              <li>
                <a href="https://www.gide.ai/about-us/career-at-gide">Careers at GIDE</a>
              </li>
              <li>
                <a href="https://www.gide.ai/about-us/contact">Contact us</a>
              </li>
            </ul>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4">
            <ul>
              <li class="footer-header">
                <a>More</a>
              </li>
              <li>
                <a href="https://web.gide.ai/GideEventsV1">Events & Activities</a>
              </li>
              <li>
                <a href="https://www.gide.ai/profile-building">Profile Building</a>
              </li>
              <li>
                <a href="https://www.gide.ai/gide-for-schools">GIDE For Schools</a>
              </li>
              <li>
                <a href="https://www.gide.ai/gide-for-universities">GIDE For Universities</a>
              </li>
              <li>
                <a href="https://www.gide.ai/gide-plans">GIDE Student Club Plans</a>
              </li>
              <li>
                <a :href="FAQLIST">FAQs</a>
              </li>
              <li>
                <a href="mailto:Support@gide.ai">Support</a>
              </li>
              <li>
                <a href="https://web.gide.ai" target="_blank" class="f-footer-link">
                  <div class="text-block-59">Login to GIDE.AI</div>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 mb-4" style="padding-left: 45px;">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <span class="footer-header social">
                  MEMBER
                  </span>
                <div class="footer-logos-block mb-2">
                  <span><img class="footer-logos social-logo" src="https://cdn.prod.website-files.com/6509665fd667420652f013c1/65e98e0277a2ff7889c61be9_logo-p-500.webp"/></span>
                </div>
                <div class="footer-logos-block mb-2">
                  <span><img class="footer-logos social-logo" src="https://gide-assets.s3.amazonaws.com/playstore.png"/></span>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
                <span class="footer-header social">
                  WE SUPPORT
                </span>
                <div class="footer-logos-block mb-2">
                  <span><img class="footer-logos social-logo" src="https://cdn.prod.website-files.com/6509665fd667420652f013c1/65e98df9d31e8cbe09945c9c_Sustainable_Development_Goalsc-p-500.png"/></span>
                </div>
                <div class="footer-logos-block mb-2">
                  <span><img class="footer-logos social-logo" src="https://gide-assets.s3.amazonaws.com/appstore.png"/></span>
                </div>
              </div>
            </div>
          </div>
          <!-- Row two -->
        </div>
        <hr class="mx-5"/>
        <div class="row mx-5">
          <div class="col-12">
            <p class="test_name">Test names [SAT, IELTS, AP, ACT] are trademarks of respective owners. GIDE.AI is not affiliated.</p>
          </div>
        </div>
        <hr class="mx-5"/>
        <div class="row mx-5">
          <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <div class="logowbutton">
              <a href="/" id="w-node-_11057fe8-539b-fc4c-3929-b1edde8b155c-23432f31" class="walsh-brand-block-2 w-nav-brand" aria-label="home">
                <img class="footer-gide-logo" src="https://cdn.prod.website-files.com/6509665fd667420652f013c1/6515320d72e6ee16539e9965_GIDE%20SVG%20LOGO%20-%20WHITE.svg" loading="lazy" alt="">
              </a>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <div class="logowbutton">
              <a href="/" class="footer-bar-text" aria-label="home">
                Copyright ©2025-2030 GIDE.AI
              </a>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <div class="logowbutton">
              <a href="https://www.gide.ai/term-and-conditions" class="footer-bar-text" aria-label="home">
                Terms & Conditions
              </a>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <div class="logowbutton">
              <a href="https://www.gide.ai/privacy-policy" class="footer-bar-text" aria-label="home">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </b-container>
</template>

<style scoped>
  /* @import 'https://cdn.prod.website-files.com/6509665fd667420652f013c1/css/gidex.webflow.bf4459a0c.css'; */
  @import url('https://fonts.cdnfonts.com/css/product-sans');
</style>
<script src="https://gidex.design.webflow.com/js/site/webflow.js"></script>
<script>
import { socialvue } from "../../config/pluginInit.js"

export default {
  name: "GideFooter",
  props: {},
  data () {
    return {
      BLOGLIST: `${process.env.VUE_APP_SERVER_URL}/blogs`,
      FAQLIST: `${process.env.VUE_APP_SERVER_URL}/faqs`
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async beforeMount () {},
  async mounted () {
    socialvue.index()
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.social-icons{
  display: flex;
}
#webflowhfooter{
  font-family: 'Product Sans', sans-serif;
  font-size: 16px;
  padding-top: 80px;
  padding-bottom: 20px;
  background-color: #181147;
  color: #fff;
  ul {
    li {
    margin-bottom:10px;
    a {
      color: #fff;
    }
    }
  }

}
.footer-header {
  font-weight: 600;
  color: #fff;
  margin-bottom: 15px !important;
}
.footer-logos{
  width: 100px;
}
.footer-logos-block{
  display: flex;
}
.social-icons{
  a{
    background-color: #fff;
    padding: 9px;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    margin-right: 18px;
    position: relative;
    display: block;
    i{
    color: #181147;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0px;
    top: 2px;
    right: 0;
    bottom: 0;
    margin: auto;
    font-size: 20px;
    }
  }
  .insta{
    left: 3px !important;
    position: relative;
    top: -1px;
  }
  .facebook{
    left: 3px;
  }
  .mb-4{
    margin-bottom: 40px;
  }
}
.social{
  margin-bottom: 20px !important;
}
.footer-logos.social-logo {
  width: 130px;
  margin-bottom: 20px;
}
.f-footer-link div{
  background-color: #e5252c;
  color: #dddee7;
  letter-spacing: -.01em;
  width: max-content;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-family: Product Sans, sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  transition: color .3s;
  padding:10px;
  border-radius: 3px;
}
.test_name {
  font-size: 16px;
  color: #fff;
}
.logowbutton{
   .footer-gide-logo{
    width: 50px;
    height: 50px;
   }
   .footer-bar-text{
    color: #fff;
   }
}
.our_communities {
  display: flex;
  flex-wrap: wrap;
}
.footer-header.social{
  display: block;
  margin-bottom: 15px !important;
}
.social-logo{

}

</style>
