<template>
  <b-container fluid id="webflowhHeader" class="p-0">
    <div>
      <div class="row bg_head_image m-0">
        <div class="col-12">
          <div class="head_button">
            <span style="margin-right:10px">Launching the world's most Futuristic Career Assessment (GPaths)</span>
            <a href="/career-assessment/gpaths" class="top_bar_button w-button">Know More</a>
            <a href="https://web.gide.ai/" class="top_bar_button w-button">Login</a>
          </div>
        </div>
      </div>
      <b-navbar toggleable="lg" type="light" variant="light">
        <b-navbar-brand :href="HOMEPAGE">
          <div class="logowbutton">
            <a :href="HOMEPAGE" id="w-node-_11057fe8-539b-fc4c-3929-b1edde8b155c-23432f31" class="walsh-brand-block-2 w-nav-brand" aria-label="home">
              <img src="https://cdn.prod.website-files.com/6509665fd667420652f013c1/651fdfd3c87a9d5daabc2de4_GIDE%20SVG%20LOGO%20-%20WHITE%20(1).svg" loading="lazy" alt="" class="logo">
            </a>
          </div>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto mr-3">
            <b-nav-item-dropdown text="Career Assessment">
              <b-dropdown-item href="https://www.gide.ai/career-assessment/gpaths">GPaths</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto mr-3">
            <b-nav-item-dropdown text="Test Prep">
              <b-dropdown-item href="https://www.gide.ai/test-prep/sat">SAT®</b-dropdown-item>
              <b-dropdown-item href="https://www.gide.ai/test-prep/advanced-placement-ap">AP®</b-dropdown-item>
              <b-dropdown-item href="https://www.gide.ai/test-prep/ielts">IELTS®</b-dropdown-item>
              <b-dropdown-item href="https://www.gide.ai/test-prep/act">ACT®</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto mr-3">
            <b-nav-item href="https://www.gide.ai/premium-career-counselling">Premium Counseling</b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto mr-3">
            <b-nav-item href="https://www.gide.ai/student-club">Student Club</b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto mr-3">
            <b-nav-item href="https://www.gide.ai/study-abroad">Study Abroad</b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto mr-3">
            <b-nav-item-dropdown text="About Us">
              <b-dropdown-item href="https://www.gide.ai/about-us/who-we-are">Who We are</b-dropdown-item>
              <b-dropdown-item href="https://www.gide.ai/our-glories">Our glories</b-dropdown-item>
              <b-dropdown-item href="https://www.gide.ai/about-us/our-communities">Our Communities</b-dropdown-item>
              <b-dropdown-item :href="BLOGLIST">Our Blogs</b-dropdown-item>
              <b-dropdown-item href="https://www.gide.ai/about-us/career-at-gide">Careers at GIDE</b-dropdown-item>
              <b-dropdown-item href="https://www.gide.ai/about-us/contact">Contact us</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto mr-3">
            <b-nav-item-dropdown text="Resources">
              <b-dropdown-item href="https://www.gide.ai/gallery">Event Gallery</b-dropdown-item>
              <b-dropdown-item href="https://www.gide.ai/profile-building">Profile Building</b-dropdown-item>
              <b-dropdown-item href="https://www.gide.ai/gide-for-schools">Gide For Schools</b-dropdown-item>
              <b-dropdown-item href="https://www.gide.ai/gide-for-universities">Gide For Universities</b-dropdown-item>
              <b-dropdown-item href="https://www.gide.ai/gide-plans">GIDE Student Club Plans</b-dropdown-item>
              <b-dropdown-item href="https://web.gide.ai/login">GIDE Login</b-dropdown-item>
              <b-dropdown-item href="https://play.google.com/store/apps/details?id=com.gide.ai&hl=en_US&pli=1">Download GIDE</b-dropdown-item>
              <b-dropdown-item :href="FAQLIST">FAQ</b-dropdown-item>
              <b-dropdown-item href="https://www.gide.ai/success-schedule-10th-grade">Study Schedule X</b-dropdown-item>
              <b-dropdown-item href="https://www.gide.ai/100-day-90-marks-schedule-for-12th-grade-commerce">Study Schedule XII Commerce</b-dropdown-item>
              <b-dropdown-item href="https://www.gide.ai/100-days-to-score-90-marks---schedule-for-12th-grade-pcb">Study Schedule XII PCB</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

        </b-collapse>
      </b-navbar>
    </div>
  </b-container>
</template>

<style scoped>
  /* @import 'https://cdn.prod.website-files.com/6509665fd667420652f013c1/css/gidex.webflow.bf4459a0c.css'; */
  @import url('https://fonts.cdnfonts.com/css/product-sans');
</style>
<script src="https://gidex.design.webflow.com/js/site/webflow.js"></script>
<script>
import { socialvue } from "../../config/pluginInit.js"

export default {
  name: "GideHeader",
  props: {},
  data () {
    return {
      HOMEPAGE: "https://www.gide.ai",
      BLOGLIST: `${process.env.VUE_APP_SERVER_URL}/blogs`,
      FAQLIST: `${process.env.VUE_APP_SERVER_URL}/faqs`
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async beforeMount () {},
  async mounted () {
    socialvue.index()
  },
  methods: {}
}
</script>
<style scoped>
.logowbutton {
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  align-items: center;
  display: flex;
}
.logo{
  height: 60px;
}
b-navbar-nav{
  color: #0a0a0a !important;
  background-color: #fff;
  border-radius: 0;
  padding-left: 18px;
  padding-right: 32px;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
}
b-dropdown-item a {
  color: #000 !important;
}
#webflowhHeader a{
  /* margin-right: 25px; */
}
.bg_head_image{
  background-image: url("https://cdn.prod.website-files.com/6509665fd667420652f013c1/65b8d7ebe106a8087884dcfb_Strip2.jpg");
  background-size: cover;
  padding: 5px 0px 5px 0px;
  margin: auto;
}
.w-button{
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  background-color: #e5252c;
  border: 0;
  border-radius: 5px;
  padding: 5px 15px;
  text-decoration: none;
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
}
.head_button{
  width: max-content;
  display: block;
  text-align: center;
  margin: auto;
}
.head_button a,span{
  color: #fff !important;
  margin-right: 15px !important;
}
@media (max-width: 700px){
.head_button{
  display: table-cell;
  }
}
</style>
